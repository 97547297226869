<script lang="jsx">
import { mapGetters, mapActions } from 'vuex'
import TojoyInnerLayout from '@/components/layout/inner'

export default {
  name: 'satisfaction-index-view',

  components: {
    TojoyInnerLayout
  },

  computed: {
    ...mapGetters('satisfaction', {
      navList: 'navs'
    })
  },

  created() {
    this.loadMineRed()
    this.loadTeamRed()
  },

  methods: {
    ...mapActions('satisfaction', ['loadMineRed', 'loadTeamRed']),
    handleClickNav(navItem) {
      // navItem.num = 0 // TODO
    },
  },

  render(h) {
    return (
      <tojoy-inner-layout
        ref="tojoy-inner-layout"
        class="satisfaction-index-view"
        breadcrumb-list={this.breadcrumbList}
        navList={this.navList}
        options={{breadcrumb:true, nav:true}}
        on-click-nav={(navItem) => this.handleClickNav(navItem)}
      />
    )
  },
}
</script>

<style lang="scss">
.satisfaction-index-view {
  position: relative;
  .errorPage {
    width: auto !important;
    margin: 20px 30px !important;
    border-radius: 5px;
  }
  .ol__desc-list {
    font-size: 14px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 14px;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .info-desc {
      &__dist {
        width: 2px;
        height: 2px;
        display: block;
        background: $darkgrey;
        flex: none;
        margin-right: 4px;
        margin-left: 3px;
        margin-top: 7px;
        border-radius: 50%;
      }
      &__text {
        line-height: 18px;
        word-break: break-all;
      }
    }
  }
  .tojoy-title-content__footer {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button--small {
      height: 32px;
    }
  }
}
</style>
